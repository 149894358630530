import React from 'react';
import {
    CircularProgress,
    CssBaseline,
    Button,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';

import Layout from './Layout';
import PageHeader from '../components/pageHeader';
import ActionCards from '../components/actionCards';
import NewCustomerDetail from '../components/newCustomerDetail';
import NewCustomerDuplicates from '../components/newCustomerDuplicates';
import NewCustomerVisits from '../components/newCustomerVisits';
import NewContactHistory from '../components/newContactHistory';
import NewCustomerLeads from '../components/newCustomerLeads';
import NewCustomerAgreements from '../components/newCustomerAgreements';
import CustomerSales from '../components/customerSales';
import AgreementPaymentChart from '../components/agreementPaymentChart';
import fetch, { fetchJson } from '../utilities/fetch';
import Tile from '../containers/tile';
import sleep from '../utilities/sleep';

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
});

class Customer extends React.Component {
    state = {
        // showCustomerDetailsEditDialog: false,
        //
        // error: false,
        // loading: false,
        // snackbarOpen: false,
        contactCode: null,
        customer: null,
        duplicates: null,
        engineers: null,
        engineersLoading: true,
        history: null,
        addressLongLat: null,
        branches: null,
        leads: null,
        // saved: false,
        errors: {},
        convertLeadProducts: null,
        campaigns: [],
        requestedLeadTab: null,
        // requestedVisitTab: null,
        paymentCards: null,
        transactionStatusWaiting: true,
        agreementsAlerts: null,
        visits: null,
    };

    static getDerivedStateFromProps(props, state) {
        const { contactCode } = props.match.params;
        return {
            contactCode,
        };
    }

    componentDidMount() {
        const { contactCode } = this.state;
        this.setState({
            engineersLoading: true,
        });

        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/user')
            .then((user) => {
                this.setState({
                    user,
                });
            });

        const contactData = fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}`)
            .then((customer) => {
                this.setState({ customer });
            });
        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/engineers/')
            .then((engineers) => {
                this.setState({
                    engineers,
                    engineersLoading: false,
                });
            });
        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}/history`)
            .then((history) => {
                this.setState({ history });
            });
        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/branches')
            .then((branches) => {
                this.setState({ branches });
            });
        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}/leads`)
            .then((leads) => {
                const { leadCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
                const requestedLeadTab = findIndex(leads, { code: parseInt(leadCode, 10) });
                this.setState({ requestedLeadTab, leads });
            });

        // this.setState({
        //     paymentCards: [],
        // });

        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}/cards`)
            .then((paymentCards) => {
                this.setState({
                    paymentCards: paymentCards || [],
                });
            });

        contactData.then(() => { // Dependent on the contact
            // fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}/duplicates`)
            fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/v2/contact/${contactCode}/duplicates`)
                .then((duplicates) => {
                    this.setState({
                        duplicates,
                    });
                });
        });

        contactData.then(() => { // Dependent on the contact
            fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}/visits`)
                .then((visits) => {
                    const { visitCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
                    const requestedVisitTab = findIndex(visits, { code: parseInt(visitCode, 10) });
                    this.setState({
                        visits,
                        requestedVisitTab,
                    });
                });
        });

        contactData.then(() => { // Dependent on the contact
            fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}/agreements`)
                .then((agreements) => {
                    const { agreementCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
                    const requestedAgreementTab = findIndex(agreements, { PlanReference: parseInt(agreementCode, 10) });
                    this.setState((state) => ({
                        customer: {
                            ...state.customer,
                            agreements,
                        },
                        requestedAgreementTab,
                        // agreementsAlerts: this.checkAlertsWarnings(agreements),
                    }));
                    this.checkAlertsWarnings(agreements);
                });
        });
        contactData.then(() => { // Dependent on the contact
            fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${contactCode}/sales`)
                .then((sales) => {
                    const { saleCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
                    const requestedSaleTab = findIndex(sales, { PlanReference: parseInt(saleCode, 10) });
                    this.setState((state) => ({
                        customer: {
                            ...state.customer,
                            sales,
                        },
                        requestedSaleTab,
                    }));
                    this.setState({
                        salesAlerts: [{
                            saleCode: 1000000,
                            alerts: [],
                            warnings: [],
                            badges: [],
                        }, {
                            saleCode: 1000001,
                            alerts: [],
                            warnings: [],
                            badges: [],
                        }],
                    });
                    // this.checkAlertsWarnings(agreements);
                });
        });

        this.setState({ contactCode });
    }

    checkAlertsWarnings = async (agreements) => {
        let agreementsAlerts;
        let agreementAlerts = {};
        let newAlerts = [];
        let newWarnings = [];
        let newBadges = [];
        await agreements.map((agreement) => {
            if (agreement.arrearsAmount > 0
                && agreement.Status === 'ACTIVE') {
                newAlerts = [
                    ...newAlerts,
                    'Agreement in arrears.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Arrears Amount',
                        style: 'alert',
                        tooltip: 'Agreement in arrears',
                    },
                ];
            }
            if (agreement.items.find((item) => item.controlDeviceCode)
            && agreement.items.find((item) => item.controlDeviceState !== 'ON')) {
                newAlerts = [
                    ...newAlerts,
                    'All controllable items on the agreement have been switched off.',
                ];
            }
            if (agreement.agreementStatus === 'ACTIVE'
            && (agreement.Status === 'CANCELLED'
            || agreement.Status === 'None')) {
                newAlerts = [
                    ...newAlerts,
                    'No payment plan.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Payment Plan Status',
                        style: 'alert',
                        tooltip: 'No payment plan',
                    },
                ];
            }
            if (agreement.agreementStatus === 'CANCELLED'
            && agreement.Status === 'ACTIVE') {
                newAlerts = [
                    ...newAlerts,
                    'Agreement cancelled but plan still active.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Payment Plan Status',
                        style: 'alert',
                        tooltip: 'Agreement cancelled but plan still active',
                    },
                ];
            }
            if (agreement.items.length <= 0
                && agreement.agreementStatus === 'ACTIVE') {
                newAlerts = [
                    ...newAlerts,
                    'No rental agreement items.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Items',
                        style: 'alert',
                        tooltip: 'No rental agreement items',
                    },
                ];
            }
            if (agreement.Card === 'NO'
                && agreement.Status === 'ACTIVE') {
                newAlerts = [
                    ...newAlerts,
                    'No active card on payment plan.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Tokenised Card',
                        style: 'alert',
                        tooltip: 'No active card on payment plan',
                    },
                ];
            }
            if (agreement.agreementStatus !== 'CANCELLED'
                && agreement.items.find((item) => !item.itemCode && item.stockControlType === 'Tracked')) {
                newAlerts = [
                    ...newAlerts,
                    'Tracked item not assigned to agreement.',
                ];
            }

            if (agreement.agreementStatus !== 'CANCELLED'
                && (agreement.portalPaidAmount < (agreement.agentCollectedFee === 1 ? 0 : agreement.agentFee))) {
                newAlerts = [
                    ...newAlerts,
                    'Agent Fee not fully paid.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Agent Fee',
                        style: 'alert',
                        tooltip: 'Agent Fee not fully paid',
                    },
                ];
            }

            if (agreement.agreementStatus !== 'CANCELLED'
                && ((agreement.portalPaidAmount - (agreement.agentCollectedFee === 1 ? 0 : agreement.agentFee) < agreement.deliveryAmount))
                && agreement.deliveryAmount > 0) {
                newAlerts = [
                    ...newAlerts,
                    'Delivery Charge not fully paid.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Delivery Charge',
                        style: 'alert',
                        tooltip: 'Delivery Charge not fully paid',
                    },
                ];
            }

            if (agreement.agreementStatus !== 'CANCELLED'
                && ((agreement.portalPaidAmount - (agreement.agentCollectedFee === 1 ? 0 : agreement.agentFee) - agreement.deliveryAmount < agreement.depositAmount))
                && agreement.depositAmount > 0) {
                newAlerts = [
                    ...newAlerts,
                    'Advance Payment not fully paid.',
                ];
                newBadges = [
                    ...newBadges,
                    {
                        label: 'Advance Payment',
                        style: 'alert',
                        tooltip: 'Advance Payment not fully paid',
                    },
                ];
            }

            // / warnings
            if (agreement.items.length > 0
                && !agreement.items.find((item) => item.deliveryStatus === 1)
                && agreement.agreementStatus !== 'CANCELLED') {
                newWarnings = [
                    ...newWarnings,
                    'Agreement items not fully delivered.',
                ];
            }

            agreementAlerts = {
                agreementCode: agreement.code,
                alerts: newAlerts,
                warnings: newWarnings,
                badges: newBadges,
            };

            agreementsAlerts = [
                ...agreementsAlerts || [],
                agreementAlerts,
            ];
            newAlerts = [];
            newWarnings = [];
            newBadges = [];
        });
        // this.setState(state => ({
        //     agreementsAlerts: [
        //         ...state.agreementsAlerts || [],
        //         agreementAlerts,
        //     ],
        // }));
        this.setState({
            agreementsAlerts,
        });
        // console.log('-->', agreementsAlerts);
        // return (agreementsAlerts);
    };

    saveCustomerDetails = async (payload) => {
        if (this.validateCustomerDetails(payload) === false) throw new Error({ error: 'Generic API error' });
        console.log('Save Details Payload', payload);
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/details`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Response:', response);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                customer: {
                    ...state.customer,
                    ...body,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateCustomerDetails = (payload) => {
        const fieldOrder = [
            'title',
            'firstname',
            'surname',
            'mobileTelephoneNumber',
            'emailAddress',
        ];

        const title = (() => {
            switch (true) {
            case !payload.title: return 'Title required';
            default: return '';
            }
        })();
        const firstname = (() => {
            switch (true) {
            case !payload.firstname: return 'Firstname required';
            case payload.firstname.length < 2: return 'Firstname too short';
            case payload.firstname.length > 100: return 'Firstname too long';
            default: return '';
            }
        })();
        const surname = (() => {
            switch (true) {
            case !payload.surname: return 'Surname required';
            case payload.surname.length < 2: return 'Surname too short';
            case payload.surname.length > 100: return 'Surname too long';
            default: return '';
            }
        })();
        const mobileTelephoneNumber = (() => {
            switch (true) {
            case !payload.mobileTelephoneNumber: return 'Mobile phone required';
            case payload.mobileTelephoneNumber.length < 2: return 'Mobile phone too short';
            case payload.mobileTelephoneNumber.length > 100: return 'Mobile phone too long';
            default: return '';
            }
        })();
        const emailAddress = (() => {
            switch (true) {
            case !payload.emailAddress: return 'Email address required';
            case payload.emailAddress.length < 2: return 'Email address too short';
            case payload.emailAddress.length > 100: return 'Email address too long';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            title,
            firstname,
            surname,
            mobileTelephoneNumber,
            emailAddress,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    saveCustomerAddress = async (payload) => {
        if (this.validateCustomerAddress(payload) === false) throw new Error({ error: 'Generic API error' });
        console.log(payload);
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/address`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                customer: {
                    ...state.customer,
                    ...body.address,
                },
                history: [
                    body.history,
                    ...state.history,
                ],
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateCustomerAddress = (payload) => {
        const fieldOrder = [
            'address1',
            'townCity',
            'county',
            'postcode',
            'country',
        ];

        const address1 = (() => {
            switch (true) {
            case !payload.address1: return 'Address required';
            case payload.address1.length < 2: return 'Address too short';
            case payload.address1.length > 100: return 'Address too long';
            default: return '';
            }
        })();
        const townCity = (() => {
            switch (true) {
            case !payload.townCity: return 'Town/City required';
            case payload.townCity.length < 2: return 'Town/City too short';
            case payload.townCity.length > 100: return 'Town/City too long';
            default: return '';
            }
        })();
        const postcode = (() => {
            switch (true) {
            case !payload.postcode: return 'Postcode required';
            case payload.postcode.length < 2: return 'Postcode too short';
            case payload.postcode.length > 100: return 'Postcode too long';
            default: return '';
            }
        })();
        const county = (() => {
            switch (true) {
            case !payload.county: return 'County required';
            default: return '';
            }
        })();
        const country = (() => {
            switch (true) {
            case !payload.country: return 'Country required';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            address1,
            townCity,
            postcode,
            county,
            country,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    sendTokenisationLink = async (agreementCode, payload) => {
        if (this.validateSendTokenisationLink(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/tokenisationRequest`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            console.log(body);
            this.setState((state) => ({
                history: [
                    body,
                    ...state.history,
                ],
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendControlDeviceCommand = async (controlDeviceCode, contactCode, command) => {
        let response;
        let body;
        const payload = {
            command,
            contactCode,
        };

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/control-device/${controlDeviceCode}/command`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            console.log(body);
            this.setState((state) => ({
                history: [
                    body,
                    ...state.history,
                ],
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateSendTokenisationLink = (payload) => {
        const fieldOrder = [
            'to',
        ];

        const to = (() => {
            switch (true) {
            case payload.messageType === 'sms'
                    && payload.destination === 'new'
                    && payload.to.length > 15:
            case payload.messageType === 'sms'
                    && payload.destination === 'new'
                    && payload.to.length < 9
                : return 'Invalid mobile number';
            case payload.messageType === 'email'
                    && payload.destination === 'new'
                    && !this.validateEmail(payload.to)
                : return 'Invalid email address';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            to,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    sendSMS = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/sms`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                history: [
                    body,
                    ...this.state.history,
                ],
            });
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addNewHistoryNote = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/history`, {
            // response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/37/sms`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        console.log(body);
        switch (response.status) {
        case 200: {
            this.setState({
                history: [
                    body,
                    ...this.state.history,
                ],
            });
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    saveNewLead = async (payload) => {
        let response;
        let body;

        // Default internal sourceCode
        payload.leadSourceCode = 100;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/lead`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        console.log(body);
        switch (response.status) {
        case 200: {
            this.setState({
                leads: [
                    body,
                    ...this.state.leads,
                ],
            });
            const note = `New lead created...\n${body.campaignTitle} - ${body.campaignDescription}`;
            await this.addNewHistoryNote({ note });

            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    updateLead = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/lead/${payload.code}`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 204: {
            const updatedLeads = [];
            this.state.leads.map((lead) => {
                if (lead.code === payload.code) {
                    updatedLeads.push({ ...lead, status: payload.status });
                } else {
                    updatedLeads.push({ ...lead });
                }
            });
            this.setState({
                leads: updatedLeads,
            });
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    saveLeadUpdate = async (updateLead) => {
        const currentStatus = this.state.leads.find((currentLead) => currentLead.code === updateLead.code).status;
        if (updateLead.status !== currentStatus) {
            // Update Lead Status
            await this.updateLead(updateLead);
            // Save History Note
            const updateLeadStatusNote = `Lead status changed from '${currentStatus}' to '${updateLead.status}'.`;
            await this.addNewHistoryNote({ note: updateLeadStatusNote });
        }

        if ((updateLead.note || '').length > 0) {
            // Save history note
            await this.addNewHistoryNote({ note: updateLead.note });
        }
    };

    fetchConvertLeadProducts = async (rentalAgreementType) => {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/products');
        const params = {
            continuousRental: rentalAgreementType === 'Continuous' ? 1 : 0,
            repaymentRental: rentalAgreementType === 'Repayment' ? 1 : 0,
            sale: rentalAgreementType === 'Sale' ? 1 : 0,
        };
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then((response) => response.json());
        this.setState({ convertLeadProducts: data });
    };

    // fetchPaymentCards = async () => {
    //     const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/cards`);
    //     const data = await fetch(url).then(response => response.json());
    //     this.setState({ paymentCards: data });
    // }

    fetchCampaigns = async () => {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/campaigns');
        const data = await fetch(url).then((response) => response.json());
        this.setState({ campaigns: data });
    };

    convertLead = async (convertForm) => {
        // API - Create rentalAgreement record
        // API - Create rentalAgreement payment plan
        // API - Create adelante customer if required
        // API - Create adelante payment plan

        // Send deposit request sms if required
        // send deposit request email if required
        // Change lead status
        // Add history note including agreement code

        convertForm.contactCode = this.state.customer.code;
        let response;
        let body;

        try {
            response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/new-rental-agreement', {
                method: 'POST',
                body: JSON.stringify(convertForm),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 202: {
            await this.saveLeadUpdate({
                code: convertForm.code,
                status: 'Converted',
            });
            const updatedLeads = [];
            this.state.leads.map((lead) => {
                if (lead.code === convertForm.code) {
                    updatedLeads.push({ ...lead, status: 'Converted' });
                } else {
                    updatedLeads.push({ ...lead });
                }
            });
            this.setState({
                leads: updatedLeads,
            });
            return Promise.resolve(body.rentalAgreementCode);
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    manualPayment = async (payment) => {
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${payment.agreementCode}/payment-plan-link`);
            const params = {
                amount: payment.amount,
                paymentExpiry: payment.paymentExpiry,
                paymentReturnURL: payment.paymentReturnURL,
            };
            Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
            response = await fetch(url);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            console.log(body.link);
            window.location.href = body.link;
            // return Promise.resolve();
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    tokenManualPayment = async (payment) => {
        console.log(payment);
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${payment.agreementCode}/token-manual-payment`);
            // const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/test`);
            const params = {
                paymentAmount: payment.paymentAmount,
                paymentCardToken: payment.paymentCardToken,
            };
            const options = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            response = await fetch(url, options);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const note = `Manual payment attempt for £${parseFloat(payment.paymentAmount).toFixed(2)} using tokenised card ${payment.paymentCardNo} was "${body.transactionStatus}".`;
            await this.addNewHistoryNote({ note });
            return Promise.resolve(body.transactionStatus);
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    printDeliveryLabels = async (agreementCode) => {
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/label`);
            // const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/test`);
            const params = {
                labelCode: 1000,
                printerCode: 1000,
            };
            const options = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            response = await fetch(url, options);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                history: [
                    body,
                    ...state.history,
                ],
            }));
            return Promise.resolve(body.transactionStatus);
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    // AGREEMENT UPDATES
    cancelAgreement = (agreementCode, params) => this.updateAgreement(agreementCode, params);

    updateAgreement = async (agreementCode) => {
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}`);
            const params = {
                agreementCode,
                status: 'CANCELLED',
            };
            const options = {
                method: 'PATCH',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            response = await fetch(url, options);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            // this.setState(state => ({
            //     customer: {
            //         ...state.customer,
            //         agreements: body.agreements,
            //     },
            //     history: [
            //         body.history,
            //         ...state.history,
            //     ],
            // }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    // PAYMENT PLAN UPDATES
    updatePaymentPlanStatus = (agreementCode, params) => this.updatePaymentPlan(agreementCode, params);

    updatePaymentPlan = async (agreementCode, params) => {
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/paymentPlan/${agreementCode}`);
            const options = {
                method: 'PATCH',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            response = await fetch(url, options);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                history: [
                    body.history,
                    ...state.history,
                ],
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    handleOldCustomerPageClick = () => {
        this.props.history.push(`/oldCustomer/${this.state.contactCode}`);
    };

    checkTransactionStatus = async (rentalAgreementCode, paymentID) => {
        this.setState({
            transactionStatusWaiting: true,
        });
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/payment');
        const params = {
            rentalAgreementCode,
            paymentID,
        };
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then((response) => response.json());
        await sleep(1000);
        this.setState({
            transactionStatusWaiting: false,
            transactionStatus: data.transactionStatus,
        });
    };

    recordDelivery = async (agreementCode, payload) => {
        console.log('Record Delivery Payload', JSON.stringify(payload, 3));
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/delivery`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Response:', response);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                history: [
                    body,
                    ...state.history,
                ],
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    handleNewCustomerPageClick = () => {
        this.props.history.push(`/contact/${this.state.customer.code}`);
    };

    render() {
        const {
            classes,
        } = this.props;
        const {
            addressLongLat,
            agreementsAlerts,
            branches,
            campaigns,
            convertLeadProducts,
            customer,
            duplicates,
            engineers,
            engineersLoading,
            errors,
            history,
            leads,
            manualPaymentTransctionStatus,
            paymentCards,
            requestedAgreementTab,
            requestedLeadTab,
            requestedSaleTab,
            requestedVisitTab,
            salesAlerts,
            user,
            visits,
        } = this.state;

        const pageHeaderContent = customer
            ? {
                leftTitle: `${customer.title === null ? '' : customer.title} ${customer.firstname} ${customer.surname}`,
                leftText: customer.fullAddress,
            } : {
                leftTitle: 'Loading...',
                leftText: '',
            };

        const customerVisitAddDialogLoading = (engineersLoading);

        return (
            <Layout pageTitle="Customer">
                <CssBaseline />
                <PageHeader content={pageHeaderContent} />

                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={this.handleOldCustomerPageClick}
                        >
                            CLICK FOR OLD CUSTOMER PAGE
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button
                            onClick={this.handleNewCustomerPageClick}
                        >
                            CLICK FOR NEW CUSTOMER PAGE
                        </Button>
                    </Grid>
                </Grid>
                {/*
                <ActionCards />
                <Tile tileTitle="Agreement Payment Chart">
                    { customer !== null
                        ? (
                            <AgreementPaymentChart />
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                    }
                </Tile>
                */}
                <NewCustomerDetail
                    customer={customer}
                    sendSMS={this.sendSMS}
                    saveCustomerDetails={this.saveCustomerDetails}
                    saveCustomerAddress={this.saveCustomerAddress}
                    branches={branches}
                    errors={errors}
                />
                <NewCustomerDuplicates
                    customer={customer}
                    duplicates={duplicates}
                />
                <NewCustomerLeads
                    customer={customer}
                    leads={leads}
                    errors={errors}
                    saveLeadUpdate={this.saveLeadUpdate}
                    convertLeadProducts={convertLeadProducts}
                    fetchConvertLeadProducts={this.fetchConvertLeadProducts}
                    requestedLeadTab={requestedLeadTab}
                    convertLead={this.convertLead}
                    manualPayment={this.manualPayment}
                    fetchCampaigns={this.fetchCampaigns}
                    campaigns={campaigns}
                    saveNewLead={this.saveNewLead}
                />
                <NewCustomerVisits
                    customer={customer}
                    customerVisitAddDialogLoading={customerVisitAddDialogLoading}
                    engineers={engineers}
                    errors={errors}
                    requestedVisitTab={requestedVisitTab}
                    visits={visits}
                />
                <NewCustomerAgreements
                    agreementsAlerts={agreementsAlerts}
                    cancelAgreement={this.cancelAgreement}
                    checkTransactionStatus={this.checkTransactionStatus}
                    customer={customer}
                    engineers={engineers}
                    errors={errors}
                    manualPayment={this.manualPayment}
                    manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                    paymentCards={paymentCards}
                    printDeliveryLabels={this.printDeliveryLabels}
                    recordDelivery={this.recordDelivery}
                    requestedAgreementTab={requestedAgreementTab}
                    sendControlDeviceCommand={this.sendControlDeviceCommand}
                    sendTokenisationLink={this.sendTokenisationLink}
                    tokenManualPayment={this.tokenManualPayment}
                    updatePaymentPlanStatus={this.updatePaymentPlanStatus}
                    user={user}
                    history={this.props.history}
                />
                <CustomerSales
                    customer={customer}
                    errors={errors}
                    requestedSaleTab={requestedSaleTab}
                    paymentCards={paymentCards}
                    manualPayment={this.manualPayment}
                    tokenManualPayment={this.tokenManualPayment}
                    checkTransactionStatus={this.checkTransactionStatus}
                    manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                    agreementsAlerts={agreementsAlerts}
                    sendTokenisationLink={this.sendTokenisationLink}
                    printDeliveryLabels={this.printDeliveryLabels}
                    cancelAgreement={this.cancelAgreement}
                />
                <NewContactHistory
                    history={history}
                    addNewHistoryNote={this.addNewHistoryNote}
                    errors={errors}
                />
            </Layout>
        );
    }
}

export default withStyles(styles)(Customer);

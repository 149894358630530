import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    FormHelperText,
    Grid,
    Icon,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faPowerOff, faBox } from '@fortawesome/free-solid-svg-icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import big from 'big.js';
import HybridTextField from '../../hybridTextField';
import titalise from '../../../utilities/titalise';
import {
    SEATING_PRODUCT_CODE,
    FLOORING_PRODUCT_CODE,
    BLIND_PRODUCT_CODE,
    BED_PRODUCT_CODE,
    CARPET_SUB_CATEGORY_CODE,
    VINYL_SUB_CATEGORY_CODE,
    LAMINATE_SUB_CATEGORY_CODE,
    ROLLER_BLIND_SUB_CATEGORY_CODE,
    VENETIAN_BLIND_SUB_CATEGORY_CODE,
    VERTICAL_BLIND_SUB_CATEGORY_CODE,
    MIRAGE_BLIND_SUB_CATEGORY_CODE,
    PERFECT_FIT_BLIND_SUB_CATEGORY_CODE,
} from '../../../constants/static';
import { fetchJson2 } from '../../../utilities/fetch';
import sleep from '../../../utilities/sleep';

const moment = require('moment-timezone');

const styles = (theme) => ({
    chip: {
        borderRadius: 5,
        // height: 21,
        // marginLeft: theme.spacing.unit * 3,
    },
    componentContainer: {
        marginBottom: theme.spacing.unit * 4,
        width: '100%',
    },
    expansionPanel: {
        border: '1px solid #e6e7e8',
        boxShadow: 'none',
    },
    greenChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '50px',
    },
    disabledChip: {
        backgroundColor: '#dbdbdb',
        color: 'white',
        minWidth: '50px',
    },
    amberChip: {
        backgroundColor: '#FFBF00',
        color: 'white',
        minWidth: '50px',
    },
    itemDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 13,
    },
    itemExpansionPanelDetails: {
        display: 'block',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 3,
    },
    itemExpansionSummary: {
        backgroundColor: '#e6e7e8',
        paddingRight: theme.spacing.unit * 5,
    },
    itemExpansionSummaryContainerLeft: {
        display: 'flex',
    },
    itemExpansionSummaryContainerRight: {
        display: 'flex',
        float: 'right',
    },
    itemExpansionSummaryIndex: {
        marginRight: theme.spacing.unit * 3,
    },
    itemSummaryControlChip: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme.spacing.unit * 4,
        // marginRight: theme.spacing.unit * 2,
        // paddingTop: 2,
        // marginBottom: -3,
        paddingTop: 1,
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 7,
        // textAlign: 'left',
        width: '100%',
    },
    redChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '50px',
    },
    deliveryIcon: {
        color: '#fff',
        marginRight: theme.spacing.unit * 1,
        transform: 'rotateY(180deg)',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 550,
    },
    noneFound: {
        fontSize: 16,
        textAlign: 'left',
        marginTop: 16,
    },
});

class ContactAgreementItems extends React.Component {
    state = {
        assignStockItemData: {
            branchCode: '',
        },
        currentAgreementItem: null,
        disableAssignStockItemSaveButton: false,
        expanded: null,
        errors: [],
        showAssignStockItemDialog: false,
        stockItemsDataLoading: true,
        stockItems: [],
    };

    componentDidMount() {
    }

    handleChange(agreementItemCode) {
        this.setState((state) => ({
            expanded: agreementItemCode === state.expanded ? null : agreementItemCode,
        }));
    }

    handleCloseAssignStockItemDialogClick = () => {
        this.setState({
            showAssignStockItemDialog: false,
        });
    };

    handleOpenAssignStockItemDialogClick = async (agreementItem) => {
        const filters = {
            modelNo: agreementItem.product.modelNo,
            contactBranchCode: this.props.contactBranchCode,
        };

        await this.fetchStockItemData(filters);
        this.setState({
            currentAgreementItem: agreementItem,
            showAssignStockItemDialog: true,
        });
    };

    fetchStockItemData = async (filters) => {
        const {
            assignment = 'IN_STOCK',
            branchCode = null,
            modelNo = null,
        } = filters;

        this.setState({
            stockItemsDataLoading: true,
        });

        try {
            const [stockItems] = await Promise.all([fetchJson2(`v2/items?modelNo=${encodeURIComponent(modelNo)}&assignment=IN_STOCK${branchCode ? `&branchCode=${branchCode}` : ''}`), sleep(500)]);
            this.setState((state) => ({
                stockItems: stockItems.payload,
                assignStockItemData: {
                    ...this.state.assignStockItemData,
                    stockItemCode: '',
                },
            }));
        } finally {
            this.setState({
                stockItemsDataLoading: false,
            });
        }
    };

    updateAssignStockItemData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            assignStockItemData: {
                ...state.assignStockItemData,
                [name]: formattedValue,
            },
        }));
    };

    updateAssignStockItemDataBranch = async (event) => {
        const { name, value } = event.target;
        const filters = {
            modelNo: this.state.currentAgreementItem.modelNo,
            branchCode: value,
        };
        await this.fetchStockItemData(filters);
        await this.updateAssignStockItemData(event);
    };

    handleSaveAssignStockItemClick = () => {
        this.setState({
            disableAssignStockItemSaveButton: true,
        });
        const agreementCode = this.props.agreement.code;
        const agreementItemCode = this.state.currentAgreementItem.code;
        const { stockItemCode } = this.state.assignStockItemData;

        this.props.assignStockItem(agreementCode, agreementItemCode, stockItemCode).then(() => {
            this.setState({
                disableAssignStockItemSaveButton: false,
                showAssignStockItemDialog: false,
            });
            this.props.enqueueSnackbar('Item Assigned', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAssignStockItemSaveButton: false,
            });
            this.props.enqueueSnackbar('Assign Item Failed', { variant: 'error' });
        });
    };

    render() {
        const {
            agreement,
            agreementItemsVisitStatuses,
            branches,
            classes,
            contactAgreementsLoading,
            contactBranchCode,
            contactVisitsLoading,
            visits,
        } = this.props;
        const {
            agreementItems,
        } = agreement;
        const {
            assignStockItemData,
            disableAssignStockItemSaveButton,
            errors,
            expanded,
            showAssignStockItemDialog,
            stockItems,
            stockItemsDataLoading,
        } = this.state;

        // const agreementItemsVisitStatuses = [];
        // !contactVisitsLoading && agreement.agreementItems.map((agreementItem) => {
        //     const agreementItemCode = agreementItem.code;
        //     visits.find((visit) => {
        //         if (visit.status === 'CANCELLED') return false;
        //         visit.visitItems.find((visitItem) => {
        //             if (visitItem.rentalAgreementItemCode !== agreementItemCode) return false;
        //             agreementItemsVisitStatuses.push({
        //                 code: agreementItemCode,
        //                 status: visit.status,
        //             });
        //         });
        //     });
        // });

        return (
            <div className={classes.componentContainer}>
                <div
                    className={classes.label}
                >
                    Items
                </div>
                {(agreementItems || []).length > 0 && !contactVisitsLoading
                    ? (
                        <>
                            {agreementItems.map((agreementItem, index) => (
                                <React.Fragment
                                    key={agreementItem.code}
                                >
                                    <ExpansionPanel
                                        key={agreementItem.code}
                                        expanded={expanded === agreementItem.code}
                                        onChange={() => this.handleChange(agreementItem.code)}
                                        className={classes.expansionPanel}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`${agreementItem.code}-content`}
                                            id={`${agreementItem.code}-header`}
                                            className={classes.itemExpansionSummary}
                                            key={agreementItem.code}
                                        >
                                            <Grid container spacing={0}>
                                                <Grid item xs={6}>
                                                    <div className={classes.itemExpansionSummaryContainerLeft}>
                                                        <div className={classes.itemExpansionSummaryIndex}>
                                                            {index + 1}
                                                        </div>
                                                        <div>
                                                            <div>
                                                                {`${agreementItem.quantity} x ${agreementItem.product.manufacturer.name} ${agreementItem.product.modelNo} ${agreementItem.reference ? ` (${agreementItem.reference})` : ''}`}
                                                            </div>
                                                            <div className={classes.itemDescription}>
                                                                {`RAI${agreementItem.code} - ${agreementItem.product.shortDescription}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className={classes.itemExpansionSummaryContainerRight}>

                                                        {/*
                                                        const agreementItem = visits.find(visit => {
  if(visit.status === 'CANCELLED') return false;
  return visit.visitItems.find(visitItem => {
    return (visitItem.rentalAgreementItemCode === agreementItemCode);
  })
});

const agreementItemAvailable = agreement.agreementItems.some(agreementItem => {
  return visits.some(visit => {
    if(visit.status === 'CANCELLED') return false;
    return visit.visitItems.some(visitItem => {
      return (visitItem.rentalAgreementItemCode === agreementItem.code);
     })
  })
});

const agreementItemStatus = _.get(agreementItem, 'status', null);

console.log(agreementItemStatus, agreementItemAvailable); */}

                                                        <div className={classes.itemSummaryControlChip}>
                                                            {agreementItemsVisitStatuses.find((agreementItemsVisitStatus) => (agreementItemsVisitStatus.code === agreementItem.code && agreementItemsVisitStatus.status !== null))
                                                                ? (
                                                                    <Chip
                                                                        label={(
                                                                            <>
                                                                                <FontAwesomeIcon icon={faTruck} color="green" className={classes.deliveryIcon} />
                                                                                {agreementItemsVisitStatuses.find((agreementItemsVisitStatus) => (agreementItemsVisitStatus.code === agreementItem.code)).status.replace('_', ' ')}
                                                                            </>
                                                                        )}
                                                                        className={`${classes.chip} ${agreementItemsVisitStatuses.find((agreementItemsVisitStatus) => (agreementItemsVisitStatus.code === agreementItem.code)).status === 'SCHEDULED'
                                                                            ? classes.greenChip
                                                                            : classes.amberChip
                                                                        }`}
                                                                    />
                                                                )
                                                                : (
                                                                    <Chip
                                                                        label={(
                                                                            <>
                                                                                <FontAwesomeIcon icon={faTruck} color="green" className={classes.deliveryIcon} />
                                                                                NOT BOOKED
                                                                            </>
                                                                        )}
                                                                        className={`${classes.chip} ${classes.redChip}`}
                                                                    />
                                                                )}
                                                        </div>

                                                        {/* <div className={classes.itemSummaryControlChip}>
                                                        {agreementItem.deliveryStatus
                                                            ? <Chip label="DELIVERED" className={`${classes.chip} ${classes.greenChip}`} />
                                                            : <Chip label="UNDELIVERED" className={`${classes.chip} ${classes.redChip}`} />
                                                        }
                                                    </div> */}

                                                        <div className={classes.itemSummaryControlChip}>
                                                            {agreementItem.product.stockControlType === 'TRACKED'
                                                                ? (
                                                                    <>
                                                                        {agreementItem.item
                                                                            ? (
                                                                                <Chip
                                                                                    label={(
                                                                                        <>
                                                                                            <FontAwesomeIcon icon={faBox} color="green" className={classes.deliveryIcon} />
                                                                                            ASSIGNED
                                                                                        </>
                                                                                    )}
                                                                                    className={`${classes.chip} ${classes.greenChip}`}
                                                                                />
                                                                            )
                                                                            : (
                                                                                <Chip
                                                                                    label={(
                                                                                        <>
                                                                                            <FontAwesomeIcon icon={faBox} color="green" className={classes.deliveryIcon} />
                                                                                            NOT ASSIGNED
                                                                                        </>
                                                                                    )}
                                                                                    className={`${classes.chip} ${classes.redChip}`}
                                                                                />
                                                                            )}
                                                                    </>
                                                                )
                                                                : (
                                                                    <Chip
                                                                        style={{ color: 'white' }}
                                                                        label={(
                                                                            <>
                                                                                <FontAwesomeIcon icon={faBox} color="green" className={classes.deliveryIcon} />
                                                                                COUNT
                                                                            </>
                                                                        )}
                                                                        className={`${classes.chip} ${classes.diabledChip}`}
                                                                    />
                                                                )}
                                                        </div>

                                                        <div className={classes.itemSummaryControlChip}>
                                                            {agreementItem.item && agreementItem.item.controlDevice
                                                                ? (
                                                                    <>
                                                                        {agreementItem.item.controlDevice.state === 'ON'
                                                                            ? (
                                                                                <Chip
                                                                                    label={(
                                                                                        <>
                                                                                            <FontAwesomeIcon icon={faPowerOff} color="green" className={classes.deliveryIcon} />
                                                                                            ON
                                                                                        </>
                                                                                    )}
                                                                                    className={`${classes.chip} ${classes.greenChip}`}
                                                                                />
                                                                            )
                                                                            : (
                                                                                <Chip
                                                                                    label={(
                                                                                        <>
                                                                                            <FontAwesomeIcon icon={faPowerOff} color="green" className={classes.deliveryIcon} />
                                                                                            OFF
                                                                                        </>
                                                                                    )}
                                                                                    className={`${classes.chip} ${classes.redChip}`}
                                                                                />
                                                                            )}
                                                                    </>
                                                                )
                                                                : (
                                                                    <Chip
                                                                        style={{ color: 'white' }}
                                                                        label={(
                                                                            <>
                                                                                <FontAwesomeIcon icon={faPowerOff} color="green" className={classes.deliveryIcon} />
                                                                                n/a
                                                                            </>
                                                                        )}
                                                                        className={`${classes.chip} ${classes.diabledChip}`}
                                                                    />
                                                                )}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails
                                            className={classes.itemExpansionPanelDetails}
                                        >
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Product Type"
                                                        text={agreementItem.product.type.description}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Product Code"
                                                        text={`P${agreementItem.product.code}`}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Reference"
                                                        text={agreementItem.reference}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Colour"
                                                        text={`${agreementItem.product.colour ? agreementItem.product.colour : ''}${agreementItem.product.colourCode ? ` (${agreementItem.product.colourCode})` : ''}`}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Repayment Amount"
                                                        text={`£${(agreementItem.repaymentAmount || 0).toFixed(2)}`}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Installation Amount"
                                                        text={`£${(agreementItem.installationAmount || 0).toFixed(2)}`}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Width"
                                                        text={
                                                            agreementItem.product.stockControlType === 'TRACKED'
                                                                ? (
                                                                    agreementItem.width
                                                                        ? (
                                                                            agreement.productTypeCode === 1004
                                                                                ? `${big(agreementItem.width).times(big(1000))}mm`
                                                                                : `${agreementItem.width.toFixed(3)}m`
                                                                        )
                                                                        : ''
                                                                )
                                                                : 'n/a'
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Length"
                                                        text={
                                                            agreementItem.product.stockControlType === 'TRACKED'
                                                                ? (
                                                                    agreementItem.length
                                                                        ? (
                                                                            agreement.productTypeCode === 1004
                                                                                ? `${big(agreementItem.length).times(big(1000))}mm`
                                                                                : `${agreementItem.length.toFixed(3)}m`
                                                                        )
                                                                        : ''
                                                                )
                                                                : 'n/a'
                                                        }
                                                    />
                                                </Grid>
                                                {agreementItem.product.productTypeSubCategoryCode === 1007
                                                    ? (
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Installation Height"
                                                                text={
                                                                    agreementItem.options.installationHeight
                                                                        ? (
                                                                            `${agreementItem.options.installationHeight}m`
                                                                        )
                                                                        : ''
                                                                }
                                                            />
                                                        </Grid>
                                                    )
                                                    : null}
                                            </Grid>
                                            {
                                                agreementItem.product.productTypeSubCategoryCode === ROLLER_BLIND_SUB_CATEGORY_CODE
                                            || agreementItem.product.productTypeSubCategoryCode === VERTICAL_BLIND_SUB_CATEGORY_CODE
                                            || agreementItem.product.productTypeSubCategoryCode === MIRAGE_BLIND_SUB_CATEGORY_CODE
                                            || agreementItem.product.productTypeSubCategoryCode === VENETIAN_BLIND_SUB_CATEGORY_CODE
                                                    ? (
                                                        <>
                                                            <Grid container spacing={0}>
                                                                {
                                                                    agreementItem.product.productTypeSubCategoryCode === ROLLER_BLIND_SUB_CATEGORY_CODE
                                                                        ? (
                                                                            <Grid item xs={4}>
                                                                                <HybridTextField
                                                                                    label="Roller Casing"
                                                                                    text={agreementItem.product.stockControlType === 'TRACKED' ? ((agreementItem.options || {}).rollerCasing ? 'Yes' : 'No') : 'n/a'}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                        : null
                                                                }
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        label="Accessory Colour"
                                                                        text={agreementItem.product.stockControlType === 'TRACKED' ? (agreementItem.options || {}).accessoryColour : 'n/a'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        label="Mount"
                                                                        text={agreementItem.product.stockControlType === 'TRACKED' && (agreementItem.options || {}).mountPosition ? titalise(agreementItem.options.mountPosition) : 'n/a'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        label="Control Side"
                                                                        text={agreementItem.product.stockControlType === 'TRACKED' ? (agreementItem.options || {}).controlSide : 'n/a'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        label="Window Opens"
                                                                        text={agreementItem.product.stockControlType === 'TRACKED' && (agreementItem.options || {}).windowOpen ? titalise(agreementItem.options.windowOpen) : 'n/a'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )
                                                    : null
                                            }

                                            {
                                                agreementItem.product.productTypeSubCategoryCode === PERFECT_FIT_BLIND_SUB_CATEGORY_CODE
                                                    ? (
                                                        <>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        label="Frame Colour"
                                                                        text={agreementItem.product.stockControlType === 'TRACKED' ? (agreementItem.options || {}).frameColour : 'n/a'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        label="Frame Depth"
                                                                        text={`${agreementItem.product.stockControlType === 'TRACKED' ? big((agreementItem.options || {}).frameDepth).times(big(1000)) : 'n/a '}mm`}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )
                                                    : null
                                            }

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Stock Control Type"
                                                        text={titalise(agreementItem.product.stockControlType)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Stock Code"
                                                        text={agreementItem.product.stockControlType === 'TRACKED' ? (agreementItem.item ? agreementItem.item.barcode : 'Unassigned') : 'n/a'}
                                                        // action={() => this.handleOpenAssignStockItemDialogClick(agreementItem)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Serial Number"
                                                        text={agreementItem.product.stockControlType === 'TRACKED' ? (agreementItem.item ? agreementItem.item.serialNumber : '') : 'n/a'}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {agreementItem.item && agreementItem.product.stockControlType === 'TRACKED'
                                                ? (
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Location Branch"
                                                                text={agreementItem.item.branch}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Location Stockroom"
                                                                text={agreementItem.item.stockroom}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Location BIN"
                                                                text={agreementItem.item.bin}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                                : ''}

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Delivery Status"
                                                        text={agreementItem.deliveryStatus ? 'Delivered' : 'Undelivered'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Delivered Time/Date"
                                                        text={agreementItem.deliveryStatus ? moment(agreementItem.deliveryDateTime).format('HH:mm DD/MM/YYYY') : 'n/a'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Delivered By"
                                                        text={agreementItem.deliveryStatus ? (agreementItem.deliveredBy ? agreementItem.deliveredBy : 'Unknown') : 'n/a'}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Control Device"
                                                        text={agreementItem.item ? (agreementItem.item.controlDevice ? 'Fitted' : 'Not Fitted') : 'n/a'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="State"
                                                        text={agreementItem.item && agreementItem.item.controlDevice ? agreementItem.item.controlDevice.state : 'n/a'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Last Sent"
                                                        text={agreementItem.item && agreementItem.item.controlDevice ? agreementItem.item.controlDevice.lastCommandSent : 'n/a'}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/*
                                        <div>
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Stock Code"
                                                        text={item.itemCode
                                                            ? `SC${item.itemCode}`
                                                            : item.stockControlType !== 'Tracked'
                                                                ? item.stockControlType
                                                                : 'Unassigned'
                                                        }
                                                        link={`/item/${item.itemCode}`}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.stockControlType === 'Tracked'
                                                        ? (
                                                            <HybridTextField
                                                                label="Serial Number"
                                                                text={item.serialNumber}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField label="Delivery Status" text={item.deliveryStatus ? 'Delivered' : 'Undelivered'} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.deliveryStatus
                                                        ? (
                                                            <HybridTextField
                                                                label="Delivery Time/Date"
                                                                text={item.deliveryDateTime}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.deliveryStatus
                                                        ? (
                                                            <HybridTextField
                                                                label="Delivered By"
                                                                text={item.deliveredByFullName || ''}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Control Device"
                                                        text={item.controlDeviceCode ? 'Fitted' : 'None'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.controlDeviceCode
                                                        ? (
                                                            <HybridTextField
                                                                label="State"
                                                                text={item.controlDeviceState}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.controlDeviceCode
                                                        ? (
                                                            <HybridTextField
                                                                label="Last Sent"
                                                                text={item.lastCommandSent}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                */}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <Dialog
                                        open={showAssignStockItemDialog}
                                        onClose={this.handleCloseAssignStockItemDialogClick}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent className={classes.dialogContent}>
                                            <div className={classes.dialogContentContainer}>
                                                <>
                                                    <Typography variant="h6" gutterBottom>
                                                        Assign Stock Item
                                                    </Typography>
                                                    {!stockItemsDataLoading
                                                        ? (
                                                            <>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            className={classes.menuFormControl}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                        >
                                                                            <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Stock Location Branch</InputLabel>
                                                                            <Select
                                                                                keyboard="true"
                                                                                input={(
                                                                                    <OutlinedInput
                                                                                        onChange={this.updateAssignStockItemDataBranch}
                                                                                        name="branchCode"
                                                                                        id="branchCode"
                                                                                    />
                                                                                )}
                                                                                value={assignStockItemData.branchCode || ''}
                                                                            >
                                                                                <MenuItem key="empty" value="">All</MenuItem>
                                                                                {/* {stockItems.filter(stockItem => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map(stockItem => ( */}
                                                                                {branches.map((branch) => (
                                                                                    [
                                                                                        <MenuItem
                                                                                            key={branch.code}
                                                                                            value={branch.code}
                                                                                        >
                                                                                            {branch.name}
                                                                                        </MenuItem>,
                                                                                    ]
                                                                                ))}
                                                                            </Select>
                                                                            {errors.branchCode && (
                                                                                <FormHelperText>{errors.branchCode}</FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        {stockItems.length > 0
                                                                            ? (
                                                                                <FormControl
                                                                                    className={classes.menuFormControl}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    required
                                                                                >
                                                                                    <InputLabel htmlFor="stockItemCode" className={classes.inputLabel}>Stock Item</InputLabel>
                                                                                    <Select
                                                                                        keyboard="true"
                                                                                        input={(
                                                                                            <OutlinedInput
                                                                                                onChange={this.updateAssignStockItemData}
                                                                                                name="stockItemCode"
                                                                                                id="stockItemCode"
                                                                                                disabled={stockItems.length === 0}
                                                                                            />
                                                                                        )}
                                                                                        value={assignStockItemData.stockItemCode || ''}
                                                                                    >
                                                                                        {/* {stockItems.filter(stockItem => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map(stockItem => ( */}
                                                                                        {stockItems.map((stockItem) => (
                                                                                            [
                                                                                                <MenuItem
                                                                                                    key={stockItem.code}
                                                                                                    value={stockItem.code}
                                                                                                >
                                                                                                    <Grid container spacing={0}>
                                                                                                        <Grid item md={2}>
                                                                                                            {`SC${stockItem.code}`}
                                                                                                        </Grid>
                                                                                                        <Grid item md={3}>
                                                                                                            {stockItem.assignment}
                                                                                                        </Grid>
                                                                                                        <Grid item md={4}>
                                                                                                            {stockItem.branch}
                                                                                                        </Grid>
                                                                                                        <Grid item md={2}>
                                                                                                            {stockItem.condition}
                                                                                                        </Grid>
                                                                                                        <Grid item md={2}>
                                                                                                            {stockItem.supplierOrderCustomerReference
                                                                                                                ? (
                                                                                                                    <>
                                                                                                                        {`(${stockItem.supplierOrderCustomerReference})`}
                                                                                                                    </>
                                                                                                                )
                                                                                                                : null}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </MenuItem>,
                                                                                            ]
                                                                                        ))}
                                                                                    </Select>
                                                                                    {errors.stockItemCode && (
                                                                                        <FormHelperText>{errors.stockItemCode}</FormHelperText>
                                                                                    )}
                                                                                </FormControl>
                                                                            )
                                                                            : (
                                                                                <Typography className={classes.noneFound}>None Found</Typography>
                                                                            )}
                                                                    </Grid>
                                                                    {/* <Grid
                                                        container
                                                        spacing={0}
                                                        direction="column"
                                                        alignItems="center"
                                                        justify="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Button
                                                                className={classes.dialogButton}
                                                                onClick={this.handleEngineerSchedulerClick}
                                                            >
                                                                SCHEDULER
                                                            </Button>
                                                        </Grid>
                                                    </Grid> */}
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            disabled={disableAssignStockItemSaveButton || !assignStockItemData.stockItemCode}
                                                                            onClick={this.handleSaveAssignStockItemClick}
                                                                        >
                                                                            SAVE
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>

                                                        )
                                                        : (
                                                            <div className={classes.progressContainer}>
                                                                <CircularProgress className={classes.progress} variant="indeterminate" />
                                                            </div>
                                                        ) }

                                                </>
                                            </div>
                                        </DialogContent>
                                        <DialogActions className={classes.dialogActions}>
                                            <Button
                                                onClick={this.handleCloseAssignStockItemDialogClick}
                                                color="primary"
                                                autoFocus
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                </React.Fragment>
                            ))}
                        </>
                    )
                    : (<div>No items found</div>)}

            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactAgreementItems));

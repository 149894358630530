import React from 'react';
import {
    Badge,
    Tab,
    Tabs,
} from '@material-ui/core';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import ContactAgreements from './contactAgreements';
import ContactSales from './contactSales';
import ContactLeads from './contactLeads';
import ContactVisits from './contactVisits';
import ContactHistory from './contactHistory';

const AMPLIFY5 = 1002;

const styles = (theme) => ({
    badge: {
        verticalAlign: 'middle',
        marginLeft: 16,
        marginBottom: 3,
    },
    badgeContent: {
        paddingTop: 1,
    },
    tabs: {
        marginTop: theme.spacing.unit * 6,
    },
});

const tabs = [
    {
        disabled: false,
        label: 'Agreements',
        key: 'agreements',
        allowAmplify5: true,
    },
    {
        disabled: false,
        label: 'Sales',
        key: 'sales',
        allowAmplify5: true,
    },
    {
        disabled: false,
        label: 'Visits',
        key: 'visits',
        allowAmplify5: true,
    },
    {
        disabled: false,
        label: 'Leads',
        key: 'leads',
        allowAmplify5: false,
    },
    {
        disabled: false,
        label: 'History',
        key: 'histories',
        allowAmplify5: true,
    },
];

class ContactTabs extends React.Component {
    state = {
        currentTab: 0,
    };

    componentDidMount() {
        this.updateTab();
    }

    updateTab = () => {
        switch (true) {
        case this.props.requestedLeadTab >= 0:
            this.setState({ currentTab: 3 });
            break;
        default:
            this.setState({ currentTab: 0 });
        }
    };
    // componentDidUpdate(prevProps) {
    //     if (this.props.contact !== prevProps.contact) {
    //         this.setupFormData();
    //     }
    // }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value,
        });
    };

    render() {
        const {
            addLeadNote,
            addAgreementItem,
            addNewHistoryNote,
            agreementStatuses,
            assignStockItem,
            authoriseLead,
            autoPhonePaymentSms,
            branches,
            buildSendContract,
            cancelVisit,
            classes,
            contact,
            contactAgents,
            contactAgreementsLoading,
            contactSalesLoading,
            contactHistoriesLoading,
            contactLeadsLoading,
            contactLoading,
            contactTerminatedCall,
            contactVisitsLoading,
            declineLead,
            deleteAgreementItem,
            editAgreement,
            editPaymentPlan,
            emailContract,
            emailStatement,
            engineers,
            engineersLoading,
            errors,
            escalateCall,
            exchangeAgreementItem,
            fetchAgreementsData,
            fetchSalesData,
            fetchHistoriesData,
            fetchLeadsAgreementsData,
            fetchLeadsSalesData,
            fetchNextCall,
            fetchVisitsData,
            history,
            leadStatuses,
            leadToSlowTrack,
            logCall,
            manualAdjustment,
            manualPayment,
            noResponseRequired,
            paymentCards,
            paymentCardsLoading,
            paymentLink,
            productTypes,
            refundPaymentPlan,
            refundPayments,
            registerCall,
            requestedLeadTab,
            requestedAgreementTab,
            requestSignature,
            rescheduleVisit,
            reversePayment,
            scheduleCallBack,
            scheduleDelivery,
            scheduleInterimPayment,
            scheduleRetryPayment,
            sendDeliveryInvite,
            sendLeadLink,
            sendRescheduleVisitInvite,
            sendSelfMeasureInvite,
            sendSMS,
            selfMeasurementChecked,
            standingOrderPayment,
            unassignAgreementItem,
            editLead,
            user,
            userLoading,
            users,
            usersLoading,
        } = this.props;
        const {
            currentTab,
        } = this.state;

        console.log('>>> curentContactTabs Tab:', currentTab);

        return (
            <>
                <Tabs
                    className={classes.tabs}
                    variant="fullWidth"
                    value={currentTab}
                    onChange={this.handleTabChange}
                >
                    {tabs.map((tab) => {
                        if (!userLoading) {
                        }
                        return (
                            <Tab
                                key={tab.label}
                                label={(
                                    <>
                                        {tab.label}
                                        {' '}
                                        <Badge
                                            classes={{ badge: classes.badgeContent }}
                                            className={classes.badge}
                                            badgeContent={get(contact, `[${tab.key}]`, []).filter((content) => content.status !== 'CANCELLED').length}
                                            color="secondary"
                                        >
                                            &nbsp;
                                        </Badge>
                                    </>
                                )}
                                disabled={
                                    contactAgreementsLoading
                                || contactSalesLoading
                                || tab.disabled
                                || (!userLoading && !tab.allowAmplify5 && !!(user || {}).groups.find((group) => group.code === AMPLIFY5))
                                }
                            />
                        );
                    })}
                </Tabs>
                <>
                    { tabs[currentTab].label === 'Agreements'
                        ? (
                            <>
                                <ContactAgreements
                                    // agreementsAlerts={agreementsAlerts}
                                    // cancelAgreement={this.cancelAgreement}
                                    // checkTransactionStatus={this.checkTransactionStatus}
                                    addAgreementItem={addAgreementItem}
                                    addNewHistoryNote={addNewHistoryNote}
                                    agreementStatuses={agreementStatuses}
                                    autoPhonePaymentSms={autoPhonePaymentSms}
                                    assignStockItem={assignStockItem}
                                    branches={branches}
                                    buildSendContract={buildSendContract}
                                    contact={contact}
                                    contactAgreementsLoading={contactAgreementsLoading}
                                    contactVisitsLoading={contactVisitsLoading}
                                    contactHistoriesLoading={contactHistoriesLoading}
                                    contactLoading={contactLoading}
                                    contactTerminatedCall={contactTerminatedCall}
                                    deleteAgreementItem={deleteAgreementItem}
                                    editAgreement={editAgreement}
                                    editPaymentPlan={editPaymentPlan}
                                    emailContract={emailContract}
                                    emailStatement={emailStatement}
                                    engineers={engineers}
                                    engineersLoading={engineersLoading}
                                    errors={errors}
                                    escalateCall={escalateCall}
                                    exchangeAgreementItem={this.props.exchangeAgreementItem}
                                    fetchAgreementsData={fetchAgreementsData}
                                    fetchHistoriesData={fetchHistoriesData}
                                    fetchLeadsAgreementsData={fetchLeadsAgreementsData}
                                    fetchNextCall={fetchNextCall}
                                    history={history}
                                    logCall={logCall}
                                    manualAdjustment={manualAdjustment}
                                    manualPayment={manualPayment}
                                    paymentCards={paymentCards}
                                    paymentCardsLoading={paymentCardsLoading}
                                    paymentLink={paymentLink}
                                    productTypes={productTypes}
                                    refundPaymentPlan={refundPaymentPlan}
                                    refundPayments={refundPayments}
                                    reversePayment={reversePayment}
                                    requestedAgreementTab={requestedAgreementTab}
                                    requestSignature={requestSignature}
                                    scheduleCallBack={scheduleCallBack}
                                    scheduleDelivery={scheduleDelivery}
                                    scheduleRetryPayment={scheduleRetryPayment}
                                    scheduleInterimPayment={scheduleInterimPayment}
                                    selfMeasurementChecked={selfMeasurementChecked}
                                    sendDeliveryInvite={sendDeliveryInvite}
                                    standingOrderPayment={standingOrderPayment}
                                    unassignAgreementItem={unassignAgreementItem}
                                    // contactAgreementsLoading={contactAgreementsLoading}
                                    // engineers={engineers}
                                    // manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                                    // paymentCards={paymentCards}
                                    // printDeliveryLabels={this.printDeliveryLabels}
                                    // recordDelivery={this.recordDelivery}
                                    // requestedAgreementTab={requestedAgreementTab}
                                    // sendControlDeviceCommand={this.sendControlDeviceCommand}
                                    // sendTokenisationLink={this.sendTokenisationLink}
                                    // tokenManualPayment={this.tokenManualPayment}
                                    // updatePaymentPlanStatus={this.updatePaymentPlanStatus}
                                    user={user}
                                    userLoading={userLoading}
                                    users={users}
                                    usersLoading={usersLoading}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'Sales'
                        ? (
                            <>
                                <ContactSales
                                    // agreementsAlerts={agreementsAlerts}
                                    // cancelAgreement={this.cancelAgreement}
                                    // checkTransactionStatus={this.checkTransactionStatus}
                                    addAgreementItem={addAgreementItem}
                                    addNewHistoryNote={addNewHistoryNote}
                                    agreementStatuses={agreementStatuses}
                                    assignStockItem={assignStockItem}
                                    branches={branches}
                                    buildSendContract={buildSendContract}
                                    contact={contact}
                                    contactAgreementsLoading={contactAgreementsLoading}
                                    contactVisitsLoading={contactVisitsLoading}
                                    contactHistoriesLoading={contactHistoriesLoading}
                                    contactLoading={contactLoading}
                                    deleteAgreementItem={deleteAgreementItem}
                                    editAgreement={editAgreement}
                                    editPaymentPlan={editPaymentPlan}
                                    emailContract={emailContract}
                                    emailStatement={emailStatement}
                                    engineers={engineers}
                                    errors={errors}
                                    exchangeAgreementItem={this.props.exchangeAgreementItem}
                                    fetchSalesData={fetchSalesData}
                                    fetchHistoriesData={fetchHistoriesData}
                                    fetchLeadsAgreementsData={fetchLeadsAgreementsData}
                                    fetchLeadsSalesData={fetchLeadsSalesData}
                                    manualAdjustment={manualAdjustment}
                                    manualPayment={manualPayment}
                                    paymentCards={paymentCards}
                                    paymentCardsLoading={paymentCardsLoading}
                                    paymentLink={paymentLink}
                                    productTypes={productTypes}
                                    refundPaymentPlan={refundPaymentPlan}
                                    refundPayments={refundPayments}
                                    reversePayment={reversePayment}
                                    requestSignature={requestSignature}
                                    scheduleDelivery={scheduleDelivery}
                                    scheduleRetryPayment={scheduleRetryPayment}
                                    scheduleInterimPayment={scheduleInterimPayment}
                                    sendDeliveryInvite={sendDeliveryInvite}
                                    unassignAgreementItem={unassignAgreementItem}
                                    // contactAgreementsLoading={contactAgreementsLoading}
                                    // engineers={engineers}
                                    // manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                                    // paymentCards={paymentCards}
                                    // printDeliveryLabels={this.printDeliveryLabels}
                                    // recordDelivery={this.recordDelivery}
                                    // requestedAgreementTab={requestedAgreementTab}
                                    // sendControlDeviceCommand={this.sendControlDeviceCommand}
                                    // sendTokenisationLink={this.sendTokenisationLink}
                                    // tokenManualPayment={this.tokenManualPayment}
                                    // updatePaymentPlanStatus={this.updatePaymentPlanStatus}
                                    user={user}
                                    userLoading={userLoading}
                                    users={users}
                                    usersLoading={usersLoading}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'Leads'
                        ? (
                            <>
                                <ContactLeads
                                    addLeadNote={addLeadNote}
                                    authoriseLead={authoriseLead}
                                    // agreementsAlerts={agreementsAlerts}
                                    // cancelAgreement={this.cancelAgreement}
                                    // checkTransactionStatus={this.checkTransactionStatus}
                                    // addAgreementItem={addAgreementItem}
                                    addNewHistoryNote={addNewHistoryNote}
                                    // agreementStatuses={agreementStatuses}
                                    // assignStockItem={assignStockItem}
                                    // branches={branches}
                                    // buildSendContract={buildSendContract}
                                    contact={contact}
                                    contactAgents={contactAgents}
                                    // contactAgreementsLoading={contactAgreementsLoading}
                                    // contactVisitsLoading={contactVisitsLoading}
                                    contactHistoriesLoading={contactHistoriesLoading}
                                    contactLoading={contactLoading}
                                    contactLeadsLoading={contactLeadsLoading}
                                    declineLead={declineLead}
                                    // deleteAgreementItem={deleteAgreementItem}
                                    // editAgreement={editAgreement}
                                    // editPaymentPlan={editPaymentPlan}
                                    // emailContract={emailContract}
                                    // emailStatement={emailStatement}
                                    // engineers={engineers}
                                    errors={errors}
                                    // exchangeAgreementItem={this.props.exchangeAgreementItem}
                                    // fetchSalesData={fetchSalesData}
                                    fetchHistoriesData={fetchHistoriesData}
                                    fetchLeadsAgreementsData={fetchLeadsAgreementsData}
                                    history={history}
                                    leadStatuses={leadStatuses}
                                    leadToSlowTrack={leadToSlowTrack}
                                    noResponseRequired={noResponseRequired}
                                    // fetchLeadsSalesData={fetchLeadsSalesData}
                                    // manualAdjustment={manualAdjustment}
                                    // manualPayment={manualPayment}
                                    // paymentCards={paymentCards}
                                    // paymentCardsLoading={paymentCardsLoading}
                                    // paymentLink={paymentLink}
                                    // productTypes={productTypes}
                                    // refundPaymentPlan={refundPaymentPlan}
                                    // refundPayments={refundPayments}
                                    // reversePayment={reversePayment}
                                    // requestSignature={requestSignature}
                                    // scheduleDelivery={scheduleDelivery}
                                    // scheduleRetryPayment={scheduleRetryPayment}
                                    // scheduleInterimPayment={scheduleInterimPayment}
                                    // sendDeliveryInvite={sendDeliveryInvite}
                                    // unassignAgreementItem={unassignAgreementItem}
                                    // contactAgreementsLoading={contactAgreementsLoading}
                                    // engineers={engineers}
                                    // manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                                    // paymentCards={paymentCards}
                                    // printDeliveryLabels={this.printDeliveryLabels}
                                    // recordDelivery={this.recordDelivery}
                                    // requestedAgreementTab={requestedAgreementTab}
                                    // sendControlDeviceCommand={this.sendControlDeviceCommand}
                                    // sendTokenisationLink={this.sendTokenisationLink}
                                    // tokenManualPayment={this.tokenManualPayment}
                                    // updatePaymentPlanStatus={this.updatePaymentPlanStatus}
                                    editLead={editLead}
                                    requestedLeadTab={requestedLeadTab}
                                    registerCall={registerCall}
                                    sendLeadLink={sendLeadLink}
                                    sendSelfMeasureInvite={sendSelfMeasureInvite}
                                    sendSMS={sendSMS}
                                    user={user}
                                    userLoading={userLoading}
                                    users={users}
                                    usersLoading={usersLoading}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'Visits'
                        ? (
                            <>
                                <ContactVisits
                                    // agreementsAlerts={agreementsAlerts}
                                    // cancelAgreement={this.cancelAgreement}
                                    // checkTransactionStatus={this.checkTransactionStatus}
                                    addNewHistoryNote={addNewHistoryNote}
                                    contactAgreementsLoading={contactAgreementsLoading}
                                    branches={branches}
                                    cancelVisit={cancelVisit}
                                    contact={contact}
                                    contactVisitsLoading={contactVisitsLoading}
                                    contactLoading={contactLoading}
                                    contactHistoriesLoading={contactHistoriesLoading}
                                    emailContract={emailContract}
                                    engineers={engineers}
                                    errors={errors}
                                    fetchHistoriesData={fetchHistoriesData}
                                    fetchVisitsData={fetchVisitsData}
                                    requestSignature={requestSignature}
                                    rescheduleVisit={rescheduleVisit}
                                    scheduleDelivery={scheduleDelivery}
                                    sendDeliveryInvite={sendDeliveryInvite}
                                    sendRescheduleVisitInvite={sendRescheduleVisitInvite}
                                    // contactAgreementsLoading={contactAgreementsLoading}
                                    // engineers={engineers}
                                    // manualPayment={this.manualPayment}
                                    // manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                                    // paymentCards={paymentCards}
                                    // printDeliveryLabels={this.printDeliveryLabels}
                                    // recordDelivery={this.recordDelivery}
                                    // requestedAgreementTab={requestedAgreementTab}
                                    // sendControlDeviceCommand={this.sendControlDeviceCommand}
                                    // sendTokenisationLink={this.sendTokenisationLink}
                                    // tokenManualPayment={this.tokenManualPayment}
                                    // updatePaymentPlanStatus={this.updatePaymentPlanStatus}
                                    // user={user}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'History'
                        ? (
                            <>
                                <ContactHistory
                                    addNewHistoryNote={addNewHistoryNote}
                                    contact={contact}
                                    contactAgreementsLoading={contactAgreementsLoading}
                                    contactHistoriesLoading={contactHistoriesLoading}
                                    contactLoading={contactLoading}
                                    fetchHistoriesData={fetchHistoriesData}
                                    histories={contact.histories}
                                    errors={errors}
                                />
                            </>
                        )
                        : null}
                </>
            </>
        );
    }
}

export default withStyles(styles)(ContactTabs);

import React from 'react';
import {
    AppBar,
    Button,
    CssBaseline,
    Grid,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddLeadAgreementSaleDialog from '../components/v2/addLeadAgreementSaleDialog';
import Layout from './Layout';
import { fetchJson2 } from '../utilities/fetch';
import amplify5Logo from '../amplify5Logo.png';
import retargetSalesLogo from '../retargetSalesLogo.png';

const AMPLIFY5 = 1002;
const RETARGETSALES = 1003;

const styles = (theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        flexDirection: 'row',
    },
    logo: {
        height: 40,
        float: 'inline-start',
        paddingLeft: 8,
    },
    nextCallButton: {
        marginLeft: theme.spacing.unit * 2.5,
        backgroundColor: '#6d7b83',
    },
});

class Home extends React.Component {
    state = {
        loadingNext: false,
        showAddLeadAgreementSaleDialog: false,
        userLoading: true,
    };

    async componentDidMount() {
        await fetchJson2('v2/user')
            .then((user) => {
                // console.log('>>> user:', user);
                // console.log('>>> :', !!user.payload.groups.find((group) => group.code === AMPLIFY5));
                this.setState({
                    user: user.payload,
                    userLoading: false,
                });
            });
    }

    handleNewLeadAgreementSaleClick = () => {
        this.setState({
            showAddLeadAgreementSaleDialog: true,
        });
    };

    handleNewLeadAgreementSaleCloseClick = () => {
        this.setState({
            showAddLeadAgreementSaleDialog: false,
        });
    };

    fetchNextCall = async () => {
        fetchJson2('v2/contacts/nextCall')
            .then((nextCall) => {
                window.location.href = nextCall.payload.link;
            });
    };

    render() {
        const { classes } = this.props;
        const {
            loadingNext,
            showAddLeadAgreementSaleDialog,
            user,
            userLoading,
        } = this.state;

        return (
            <Layout pageTitle="Home">
                <>
                    <CssBaseline />
                    {!userLoading && !!(user.groups || []).find((group) => group.code === AMPLIFY5)
                        ? (
                            <AppBar position="fixed" className={classes.appBar}>
                                <Toolbar>
                                    <img className={classes.logo} src={amplify5Logo} alt="logo" />
                                    <Button
                                        type="button"
                                        onClick={this.fetchNextCall}
                                        // fullWidth
                                        variant="text"
                                        color="secondary"
                                        className={classes.nextCallButton}
                                        disabled={loadingNext}
                                    >
                                        NEXT CALL
                                    </Button>
                                </Toolbar>
                            </AppBar>
                        ) : null}
                    {!userLoading && !!(user.groups || []).find((group) => group.code === RETARGETSALES)
                        ? (
                            <AppBar position="fixed" className={classes.appBar}>
                                <Toolbar>
                                    <img className={classes.logo} src={retargetSalesLogo} alt="logo" />
                                    <Button
                                        type="button"
                                        onClick={this.fetchNextCall}
                                        // fullWidth
                                        variant="text"
                                        color="secondary"
                                        className={classes.nextCallButton}
                                        disabled={loadingNext}
                                    >
                                        NEXT CALL
                                    </Button>
                                </Toolbar>
                            </AppBar>
                        ) : null}
                    {/* <Typography variant="title">
                        Shortcuts
                    </Typography>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid item xs={4}>
                            <Button
                                onClick={this.handleNewLeadAgreementSaleClick}
                                disabled={userLoading || user.userScopes.includes('sale:create')}
                            >
                                NEW LEAD/AGREEMENT/SALE
                            </Button>
                        </Grid>
                    </Grid> */}
                    {/* <AddLeadAgreementSaleDialog
                        handleNewLeadAgreementSaleCloseClick={this.handleNewLeadAgreementSaleCloseClick}
                        showAddLeadAgreementSaleDialog={showAddLeadAgreementSaleDialog}
                    /> */}
                </>
            </Layout>
        );
    }
}

export default withStyles(styles)(Home);
